@layer components {
    .input {
        @apply appearance-none flex w-full border border-gray-300 rounded-md shadow-sm focus:outline-none
    }

    .input-error {
        @apply border-red-300 focus:ring-red-500 focus:border-red-500
    }

    .select {
        @apply form-select bg-white input sm:text-sm px-3 py-2 mt-1 disabled:bg-gray-50 disabled:opacity-100
    }
}

.outline-checkbox:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 16 16' fill='%23302054' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e ") !important;
   border-color: #302054 !important;
}
