@layer components {
    .btn {
        @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50
    }
    .btn-primary {
        @apply text-white bg-secondary-500 hover:bg-secondary-700 focus:ring-secondary-700 disabled:bg-secondary-500
    }
    .btn-secondary {
        @apply text-secondary-700 bg-secondary-50 hover:bg-secondary-200 focus:ring-secondary-700 disabled:bg-secondary-50
    }
    .btn-white {
        @apply border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-secondary-500
    }
    .btn-full {
        @apply  w-full flex justify-center
    }
    .btn-delete {
        @apply text-red-800 bg-red-100 hover:bg-red-200 focus:ring-red-800 disabled:bg-red-50
    }
    .leading-icon {
        @apply -ml-0.5 mr-2 h-4 w-4
    }
    .trailing-icon {
        @apply ml-3 -mr-1 h-5 w-5
    }
}
