@layer components {
  .heading-item {
    @apply py-3 px-3 bg-gray-50 border-l border-gray-200 flex top-0 sticky flex-col;
  }

  .entry {
    @apply flex border-gray-200 border-t border-l py-4 px-4 h-20;
  }
}

.tracks,
.scroller {
  -webkit-overflow-scrolling: touch;
}

.tracks::-webkit-scrollbar,
.scroller::-webkit-scrollbar {
  display: none;
}

.track {
  flex: 1 0 190px;
}

.time {
  flex: 0 0 180px;
}
