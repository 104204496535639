@import 'button.css';
@import 'input.css';
@import 'table.css';
@import 'resource-planner.css';
@import '~animate.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  @apply h-full;
}

@layer components {
  .text-link {
    @apply text-secondary-500 hover:text-secondary-300;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.ql-editor{
  min-height:166px;
}

.ql-container, .ql-toolbar {
  border: none !important;
}

.ql-toolbar {
  border-bottom: solid 1px #E5E7EB !important;
}
.quill > .ql-container > .ql-editor.ql-blank::before{
  font-size: 16px;
  color: #9CA3AF;
  font-style: normal;
}
